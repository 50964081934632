<template>
  <div>
    <index path="/datareport/alarmTrend"></index>
    <div style="position: absolute; right: 1%; width: 81%; top: 1%">
      <div>
        <div
          class="tools-bar"
          style="
            float: right;
            padding-top: 1vh;
            padding-bottom: 1vh;
            font-size: 1.6vh;
          "
        >
          <el-select
            v-model="supplierId"
            placeholder="服务商"
            size="mini"
            style="width: 9vw; padding-right: 0.7vw"
            @change="selectSupplier"
          >
            <el-option
              v-for="item in providers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="relation"
            placeholder="客户关系"
            size="mini"
            style="width: 9vw; padding-right: 0.7vw"
            @change="selectSupplier"
          >
            <el-option
              v-for="item in relationList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="orgin"
            placeholder="终端用户"
            size="mini"
            style="width: 9vw; padding-right: 0.7vw"
            @change="changeOrgin"
            clearable
          >
            <el-option
              v-for="item in orginList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-date-picker
            size="mini"
            style="width: 15vw"
            :clearable="false"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button
            style="margin-left: 0.7vw"
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="search"
            >搜索</el-button
          >
        </div>
      </div>
      <div id="main" style="width: 100%; margin-top: 7vh; height: 80vh"></div>
    </div>
  </div>
</template>

<script>
import index from "@/views/datareport/index";
import {
  getProviders,
  getOrginList,
  getAlarmTrendData,
} from "@/api/datareport";
import echarts from "echarts";

export default {
  components: {
    index,
  },
  data() {
    let date = new Date();
    date.setDate(1);
    date.setMonth(0);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return {
      relation: "直属",
      relationList: [
        { id: 1, name: "直属" },
        { id: 2, name: "含从属" },
      ],
      orgin: null,
      orginList: [],
      providers: [],
      supplierId: null,
      date: [date, new Date()],
    };
  },
  mounted() {
    this.getProviders();
  },
  methods: {
    getProviders() {
      getProviders().then((res) => {
        if (res.data.state == "ok") {
          this.providers = res.data.data;
          this.supplierId = res.data.data[0].id;
          this.getOrginList();
        }
      });
    },
    getOrginList() {
      let params = {
        supplierId: this.supplierId,
        relation: this.relation,
      };
      getOrginList(params).then((res) => {
        if (res.data.state == "ok") {
          this.orginList = res.data.data;
          this.initChart();
        }
      });
    },
    selectSupplier() {
      this.getOrginList();
    },
    changeOrgin(){
      this.initChart()
    },
    search(){
      this.initChart()
    },
    initChart() {
      var option;
      option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        color: ["#f05326", "#38cb7d", "#eed777", "#3682be", "#c97937"],
        legend: {
          data: ["火警", "漏水", "温度", "湿度", "故障"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            alignWithLabel: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            x: "left",
            show: true,
          },
          data: [],
        },
        yAxis: {
          minInterval: 1,
          type: "value",
          axisLine: {
            show: true,
          },
          splitLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
        },
        series: [],
      };
      let begin =
        this.date && this.date.length > 1 && this.date[0]
          ? new Date(+new Date(this.date[0]) + 8 * 3600 * 1000)
              .toISOString()
              .replace(/T/g, " ")
              .replace(/\.[\d]{3}Z/, "")
          : null;
      let end =
        this.date && this.date.length > 1 && this.date[1]
          ? new Date(+new Date(this.date[1]) + 8 * 3600 * 1000)
              .toISOString()
              .replace(/T/g, " ")
              .replace(/\.[\d]{3}Z/, "")
          : null;
      let orginIds = ''
      if (this.orgin != null && this.orgin != undefined && this.orgin != '') {
        orginIds = this.orgin
      } else {
        this.orginList.forEach(element => {
          orginIds = orginIds + element.id + ','
        });
        orginIds = orginIds.substr(0,orginIds.length-1)
      }
      let params = {
        orginIds: orginIds,
        timeBegin: begin,
        timeEnd: end,
      };
      let seriesData = []
      getAlarmTrendData(params).then((res) => {
        if (res.data.state == "ok") {
          let data = res.data.data
          let fire = {
            name: "火警",
            type: "line",
            stack: "Total",
            data: []
          }
          seriesData.push(fire)
          let water = {
            name: "漏水",
            type: "line",
            stack: "Total",
            data: []
          }
          seriesData.push(water)
          let temperature = {
            name: "温度",
            type: "line",
            stack: "Total",
            data: [],
          }
          seriesData.push(temperature)
          let humidity = {
            name: "湿度",
            type: "line",
            stack: "Total",
            data: [],
          }
          seriesData.push(humidity)
          let fault = {
            name: "故障",
            type: "line",
            stack: "Total",
            data: [],
          }
          seriesData.push(fault)
          let datetime = []
          data.forEach(element => {
            datetime.push(element.m)
          });
          //去重排序
          var newDatetime = (Array.from(new Set(datetime))).sort();
          if (newDatetime.length >0) {
            for (let index = 0; index < newDatetime.length; index++) {
              const element = newDatetime[index];
              let fireStaus = 0;
              let waterStaus = 0;
              let temperatureStaus = 0
              let humidityStaus = 0
              let faultStaus = 0
              data.forEach(e => {
                if (element == e.m) {
                  if (e.type == 1) {
                    fire.data.push(e.count)
                    fireStaus = fireStaus + 1
                  }
                  if (e.type == 2) {
                    water.data.push(e.count)
                    waterStaus= waterStaus + 1
                  }
                  if (e.type == 3) {
                    temperature.data.push(e.count)
                    temperatureStaus = temperatureStaus + 1
                  }
                  if (e.type == 4) {
                    humidity.data.push(e.count)
                    humidityStaus = humidityStaus + 1
                  }
                  if (e.type == 5) {
                    fault.data.push(e.count)
                    faultStaus = faultStaus + 1
                  }
                }
              });
              if (fireStaus==0) {
                fire.data.push(0)
              }
              if (waterStaus==0) {
                water.data.push(0)
              }
              if (temperatureStaus==0) {
                temperature.data.push(0)
              }
              if (humidityStaus==0) {
                humidity.data.push(0)
              }
              if (faultStaus==0) {
                fault.data.push(0)
              }
            }
          }
          option.series = seriesData
          option.xAxis.data = newDatetime
          var chartDom = document.getElementById("main");
          var myChart = echarts.init(chartDom);
          option && myChart.setOption(option);
        }
      });
    },
  },
};
</script>
