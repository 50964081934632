<template>
  <div style="position: absolute; left: 1%; top: 2%; width: 15%">
    <el-menu :default-active="path" class="el-menu-vertical-demo" router>
      <el-menu-item index="/datareport/sunburst" style="text-align: center">
        <span slot="title">服务商 | 终端用户 | 设备</span>
      </el-menu-item>
      <el-menu-item index="/datareport/alarmTrend" style="text-align: center">
        <span slot="title">警情趋势</span>
      </el-menu-item>
      <el-menu-item index="/datareport/customerGrowth" style="text-align: center">
        <span slot="title">客户增长趋势</span>
      </el-menu-item>
      <el-menu-item index="/datareport/annualReport" style="text-align: center">
        <span slot="title">客户年/季度报告</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    path: String,
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
</style>