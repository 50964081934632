import request from '@/utils/request'

export function getSunburstChart(params) {
    return request({
        url: '/api/datareport/getSunburstChart',
        method: 'get',
        params: params
    })
}

//获取当前账号服务商及下级服务商
export function getProviders(params) {
    return request({
        url: '/api/datareport/getProviders',
        method: 'get',
        params: params
    })
}

//获取客户列表
export function getOrginList(params) {
    return request({
        url:'/api/datareport/getOrginList',
        method: 'get',
        params:params
    })
}

//获取初始化数据
export function getAlarmTrendData(params) {
    return request({
        url:'/api/datareport/getAlarmTrendData',
        method: 'get',
        params:params
    })
}

/**
 * @author xiaoying
 * @description 获取客户增长趋势数据
 */
export function getCustomerGrowthData(params) {
    return request({
        url:'/api/datareport/getCustomerGrowthData',
        method: 'get',
        params:params
    })
}

/**
 * @author xiaoying
 * @description 根据supplerId获取终端用户
 */
 export function getOrginListBySupplierId(params) {
    return request({
        url:'/api/datareport/getOrginListBySupplierId',
        method: 'get',
        params:params
    })
}

/**
 * @author xiaoying
 * @description 库房环境监测数据报告
 */
 export function getReportEnvironmentData(params) {
    return request({
        url:'/api/datareport/getReportEnvironmentData',
        method: 'get',
        params:params
    })
}

/**
 * @author xiaoying
 * @description 获取年份
 */
 export function getYearList(params) {
    return request({
        url:'/api/datareport/getYearList',
        method: 'get',
        params:params
    })
}